<template>
  <div class="translation-app">
    <h1>PDF文献翻译</h1>


    <div class="upload-section">
      <h2></h2>
      <input type="file" @change="onFileChange" ref="fileInput" style="display: none;" />
      <div class="drop-zone" @dragover.prevent="handleDragOver" @drop.prevent="onDrop" @click="triggerFileInput">
        <p>拖动或点击这里选择文件</p>
      </div>
      <div v-if="fileName" class="file-info">
        <p>已上传文件: {{ fileName }}</p>
      </div>
    </div>

    <!-- 邮箱输入区域 -->
    <div class="email-section">
      <h2>输入接收翻译结果的邮箱</h2>
      <input
          class="email-button"
          type="email"
          v-model="email"
          placeholder="请输入邮箱"
          @blur="validateEmail"
      />
      <p v-if="emailError" class="error">{{ emailError }}</p>
    </div>

    <!-- 确认和取消按钮 -->
    <div class="button-section">
      <button @click="confirmTranslation">翻译为PDF</button>
      <button @click="confirmtranslateword">翻译为WORD</button>
      <button @click="confirmtranslateall">全都要</button>
      <button @click="cancelTranslation">取消</button>
    </div>
  </div>
</template>

<script>
import http from "@/api/httpRequest";
import axios from "axios";
export default {
  data() {
    return {
      file: null,
      fileName: '',
      email: '',
      emailError: '',
      isDragging: false,
    };
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click(); // 触发隐藏的file input的点击事件
    },
    onFileChange(event) {
      const file = event.target.files[0];
      if (file && file.type === 'application/pdf') {
        this.file = file;
        this.fileName = file.name;
        this.uploadFile();
      } else {
        this.file = null;
        this.fileName = '';
        alert('请选择一个PDF文件！');
      }
    },
    handleDragOver(event) {
      // 阻止默认行为，允许文件放置
      event.preventDefault();
      this.isDragging = true;
    },
    onDrop(event) {
      this.isDragging = false;
      const files = event.dataTransfer.files;
      if (files.length > 0) {
        const file = files[0];
        if (file.type === 'application/pdf') {
          this.file = file;
          this.fileName = file.name;
          this.uploadFile();
        } else {
          this.fileName = null;
          alert('请拖放一个PDF文件！');
        }
      }
    },
    uploadFile() {
      let formData = new FormData();
      formData.append('file', this.file);

      axios.post('http://wangch.website/translate/files', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
          .then(response => {
            alert('文件上传成功');
          })
          .catch(error => {
            this.fileName=null;
            alert('文件上传失败');
          });
    },
    validateEmail() {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailPattern.test(this.email)) {
        this.emailError = '请输入有效的邮箱地址';
      } else {
        this.emailError = '';
      }
    },
    confirmTranslation() {
      if (!this.fileName || !this.email || this.emailError) {
        alert('请确保文件已上传并输入有效的邮箱地址');
        return;
      }
      const email = this.email.valueOf();
      const fileName = this.fileName.valueOf();
      axios.post('http://wangch.website/translate/files/translate',{email,fileName},{headers:{
        'Content-Type':'application/json;charset=UTF-8'}})
      this.$message.success("翻译已提交!");
      this.fileName = null;
      this.email = null;
    },
    confirmtranslateword(){
      if (!this.fileName || !this.email || this.emailError) {
        alert('请确保文件已上传并输入有效的邮箱地址');
        return;
      }
      const email = this.email.valueOf();
      const fileName = this.fileName.valueOf();
      axios.post('http://wangch.website/translate/files/translateByaliyun',{email,fileName},{headers:{
          'Content-Type':'application/json;charset=UTF-8'}})
      this.$message.success("翻译已提交!");
      this.fileName = null;
      this.email = null;
    },
    confirmtranslateall(){
      if (!this.fileName || !this.email || this.emailError) {
        alert('请确保文件已上传并输入有效的邮箱地址');
        return;
      }
      const email = this.email.valueOf();
      const fileName = this.fileName.valueOf();
      axios.post('http://wangch.website/translate/files/translate',{email,fileName},{headers:{
          'Content-Type':'application/json;charset=UTF-8'}})
      axios.post('http://wangch.website/translate/files/translateByaliyun',{email,fileName},{headers:{
          'Content-Type':'application/json;charset=UTF-8'}})
      this.$message.success("翻译已提交!");
      this.fileName = null;
      this.email = null;
    },
    cancelTranslation() {
      this.file = null;
      this.fileName = '';
      this.email = '';
      this.emailError = '';
    }
  },
  mounted() {
    document.addEventListener('dragover', (event) => {
      event.preventDefault();
      this.isDragging = true;
    });
    document.addEventListener('dragleave', (event) => {
      this.isDragging = false;
    });
  }
};
</script>

<style scoped>
.translation-app {
  max-width: 1500px;
  height: 800px; /* 设置固定高度 */
  margin: 40px auto 40px; /* 上边距增加为40px，左右自动居中，下边距保持为0 */
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-image: url('../assets/123.jpg'); /* 指定背景图片路径 */
  background-position: center center; /* 背景图片居中 */
  background-size: cover; /* 背景图片覆盖整个元素 */
  background-repeat: no-repeat; /* 背景图片不重复 */

}
.email-button{
  height: 30px; /* 设置固定高度 */
  width: 300px;
}


.upload-section h2 {
  margin-top: 0; /* 去除上边距 */
  color: #333; /* 深灰色文本 */
}
.upload-section input[type="file"] {
  display: none;
}

.drop-zone {
  display: inline-block; /* 允许设置宽度和高度 */
  width: 1000px; /* 宽度稍小于父容器 */
  height: 350px; /* 设置固定高度 */
  padding: 20px; /* 添加内边距 */
  margin-top: 20px; /* 与标题保持一定距离 */
  border: 2px dashed #888; /* 更明显的虚线边框 */
  border-radius: 5px; /* 圆角边框 */
  background-color: #fff; /* 白色背景 */
  cursor: pointer; /* 鼠标悬停时显示为可点击 */
  transition: background-color 0.3s, border-color 0.3s; /* 添加过渡效果 */
}

.drop-zone.drag-over {
  border-color: #333; /* 深色边框 */
  background-color: #eee; /* 浅色背景 */
}
.drop-zone p {
  margin: 0; /* 去除外边距 */
  color: #666; /* 灰色文本 */
  font-size: 16px; /* 字体大小 */
}

.file-info {
  margin-top: 10px;
}

.file-info {
  margin-top: 20px; /* 与拖放区域保持一定距离 */
  color: #333; /* 深灰色文本 */
  font-size: 14px; /* 字体大小 */
}

.error {
  color: red;
  font-size: 12px;
}

.button-section {
  display: inline-block;
  justify-content: space-between;
}

button {
  padding: 10px 20px ;
  font-size: 16px;
  cursor: pointer;
}
</style>