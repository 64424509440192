<template>
	<el-container class="register-view">
		<div>
			<el-form :model="registerForm" status-icon :rules="rules" ref="registerForm" label-width="80px"
				class="web-ruleForm">
				<div class="register-brand">
					<img class="logo" src="../../public/logo.png" />
					<div>欢迎成为CHm的用户</div>
				</div>
				<el-form-item label="用户名" prop="userName">
					<el-input type="userName" v-model="registerForm.userName" autocomplete="off"
						placeholder="用户名"></el-input>
				</el-form-item>
				<el-form-item label="昵称" prop="nickName">
					<el-input type="nickName" v-model="registerForm.nickName" autocomplete="off"
						placeholder="昵称"></el-input>
				</el-form-item>
				<el-form-item label="密码" prop="password">
					<el-input type="password" v-model="registerForm.password" autocomplete="off"
						placeholder="密码"></el-input>
				</el-form-item>
				<el-form-item label="确认密码" prop="confirmPassword">
					<el-input type="password" v-model="registerForm.confirmPassword" autocomplete="off"
						placeholder="确认密码"></el-input>
				</el-form-item>
        <el-form-item label="手机号" prop="phonenumber">
          <el-input type="phonenumber" v-model="registerForm.phonenumber" autocomplete="off"
                    placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input type="email" v-model="registerForm.email" autocomplete="off" placeholder="请输入邮箱" class="inline-input"></el-input>
          <el-button type="primary" @click="sendVerificationCode" :disabled="isSending" class="inline-button">
            <span v-if="isSending">{{ countdown }}秒</span>
            <span v-else>发送验证码</span>
          </el-button>
        </el-form-item>
        <el-form-item label="验证码" prop="cache">
          <el-input type="cache" v-model="registerForm.cache" autocomplete="off"
                    placeholder="请输入验证码"></el-input>
        </el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('registerForm')">注册</el-button>
					<el-button @click="resetForm('registerForm')">清空</el-button>
				</el-form-item>
				<div class="to-login">
					<router-link to="/login">已有账号,前往登录</router-link>
				</div>
			</el-form>
		</div>
		<icp></icp>
	</el-container>
</template>

<script>
import Icp from '../components/common/Icp.vue'
import http from "@/api/httpRequest";
export default {
	name: "login",
	components: {
		Icp
	},
	data() {
		var checkUserName = (rule, value, callback) => {
			if (!value) {
				return callback(new Error('请输入用户名'));
			}
			callback();
		};
		var checkNickName = (rule, value, callback) => {
			if (!value) {
				return callback(new Error('请输入昵称'));
			}
			callback();
		};
		var checkPassword = (rule, value, callback) => {
			if (value === '') {
				return callback(new Error('请输入密码'));
			}
			callback();
		};

		var checkConfirmPassword = (rule, value, callback) => {
			if (value === '') {
				return callback(new Error('请输入密码'));
			}
			if (value != this.registerForm.password) {
				return callback(new Error('两次密码输入不一致'));
			}
			callback();
		};
    var checkPhonenumber = (rule, value, callback) =>{
      if (value === '') {
        return callback(new Error('请输入手机号'));
      }
      if (value.length !== 11) {
        return callback(new Error('请输入11位手机号'));
      }
      callback();
    };
    var checkEmail = (rule, value, callback) =>{
      if (value === '') {
        return callback(new Error('请输入邮箱'));
      }
      var isValidEmail = true;
      var emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      isValidEmail = emailRegex.test(value);
      if(!isValidEmail)
      {
        return callback(new Error('请输入正确邮箱'));
      }
      callback();
    };

		return {
			registerForm: {
				userName: '',
				nickName: '',
				password: '',
				confirmPassword: '',
        phonenumber: '',
        email: '',
        cache: '',
			},
      isSending: false,
      countdown: 120,
      countdownInterval: null,
			rules: {
				userName: [{
					validator: checkUserName,
					trigger: 'blur'
				}],
				nickName: [{
					validator: checkNickName,
					trigger: 'blur'
				}],
				password: [{
					validator: checkPassword,
					trigger: 'blur'
				}],
				confirmPassword: [{
					validator: checkConfirmPassword,
					trigger: 'blur'
				}],
        phonenumber:[{
          validator: checkPhonenumber,
          trigger: 'blur'
        }],
        email:[{
          validator: checkEmail,
          trigger: 'blur'
        }]
			}
		};
	},
	methods: {
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(String(email).toLowerCase());
    },
    sendVerificationCode(formName) {
      if (this.isSending || !this.validateEmail(this.registerForm.email)) {
        return this.$message.error("输入邮箱有误!");
      }
      const a = http({
        method:'post',
        url: '/yanzhen',
        data: this.registerForm.email,
      })

      console.log('Sending verification code to', this.registerForm.email);

      // 开始倒计时
      this.startCountdown();

    },
    startCountdown() {
      this.isSending = true;
      this.countdownInterval = setInterval(() => {
        if (this.countdown <= 0) {
          this.stopCountdown();
        } else {
          this.countdown -= 1;
        }
      }, 1000);
    },
    stopCountdown() {
      this.isSending = false;
      this.countdown = 120; // 重置倒计时
      clearInterval(this.countdownInterval);
      this.countdownInterval = null;
    },
    beforeDestroy() {
      // 确保在组件销毁前停止任何未完成的倒计时
      if (this.countdownInterval) {
        clearInterval(this.countdownInterval);
      }
    },
		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.$http({
						url: "/register",
						method: 'post',
						data: this.registerForm
					})
						.then((data) => {
							this.$message.success("注册成功!");
						})
				}
			});
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		}
	}
}
</script>

<style scoped lang="scss">
.register-view {
	position: fixed;
	display: flex;
	justify-content: space-around;
	align-items: center;
	width: 100%;
	height: 100%;
	background: rgb(232, 242, 255);

	.web-ruleForm {
		width: 500px;
		height: 560px;
		padding: 20px;
		background: white;
		opacity: 0.9;
		box-shadow: 0px 0px 1px #ccc;
		border-radius: 3px;
		overflow: hidden;
		border-radius: 3%;

		.register-brand {
			display: flex;
			justify-content: center;
			align-items: center;
			line-height: 50px;
			margin: 20px 0 30px 0;
			font-size: 22px;
			font-weight: 600;
			letter-spacing: 2px;
			text-align: center;
			text-transform: uppercase;

			.logo {
				width: 30px;
				height: 30px;
				margin-right: 10px;
			}
		}

		.to-login {
			display: flex;
			flex-direction: row-reverse;
			line-height: 40px;
			text-align: left;
			padding-left: 20px;
		}
    .inline-input {
      display: inline-block;
      width: calc(92% - 105px); /* 调整宽度以适应按钮 */
    }
    .inline-button {
      display: inline-block;
      width: 120px; /* 设置按钮的宽度 */
      margin-left: 10px; /* 可选：增加一些间距 */
    }

	}
}
</style>
